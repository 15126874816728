<template>
    <div class="p-1">
        <div class="mb-2">
            <a-button @click="$router.back()">
                Regresar
            </a-button>
        </div>
        <a-card v-if="preinscription">
            <div class="w-full">
                <h3 class="font-bold text-gray-800 mb-4">Datos del estudiante</h3>
                <div class="w-full flex flex-wrap -mx-2">
                    <div class="w-full md:w-1/2 lg:w-1/2 px-2">
                        <div class="flex flex-wrap mb-2">
                            <div class="w-full md:w-1/2 lg:w-1/2">
                                <b style="color: #4D4F5C">Cédula de identidad</b>
                            </div>
                            <div class="w-full md:w-1/1 lg:w-1/2">
                                {{ preinscription.id_document }}
                            </div>
                        </div>
                        <div class="flex flex-wrap mb-2">
                            <div class="w-full md:w-1/2 lg:w-1/2 truncate">
                                <b style="color: #4D4F5C">Nombres</b>
                            </div>
                            <div class="w-full md:w-1/1 lg:w-1/2">
                                {{ preinscription.first_name }}
                            </div>
                        </div>
                        <div class="flex flex-wrap mb-2">
                            <div class="w-full md:w-1/2 lg:w-1/2 truncate">
                                <b style="color: #4D4F5C">Apellidos</b>
                            </div>
                            <div class="w-full md:w-1/1 lg:w-1/2">
                                {{ preinscription.last_name }}
                            </div>
                        </div>
                        <div class="flex flex-wrap mb-2">
                            <div class="w-full md:w-1/2 lg:w-1/2 truncate">
                                <b style="color: #4D4F5C">Correo electrónico</b>
                            </div>
                            <div class="w-full md:w-1/1 lg:w-1/2">
                                {{ preinscription.email }}
                            </div>
                        </div>
                    </div>
                    <div class="w-full md:w-1/2 lg:w-1/2 px-2">
                        <div class="flex flex-wrap mb-2">
                            <div class="w-full md:w-1/2 lg:w-1/2">
                                <b style="color: #4D4F5C">Género</b>
                            </div>
                            <div class="w-full md:w-1/1 lg:w-1/2">
                                {{ preinscription.gender === 'male' ? 'Masculino' : 'Femenino' }}
                            </div>
                        </div>
                        <div class="flex flex-wrap mb-2">
                            <div class="w-full md:w-1/2 lg:w-1/2 truncate">
                                <b style="color: #4D4F5C">Turno</b>
                            </div>
                            <div class="w-full md:w-1/1 lg:w-1/2">
                                {{ getSheduleShift(preinscription.schedule_shift) }}
                            </div>
                        </div>
                        <div class="flex flex-wrap mb-2">
                            <div class="w-full md:w-1/2 lg:w-1/2 truncate">
                                <b style="color: #4D4F5C">Teléfono</b>
                            </div>
                            <div class="w-full md:w-1/1 lg:w-1/2">
                                {{ preinscription.phone }}
                            </div>
                        </div>
                        <div class="flex flex-wrap mb-2">
                            <div class="w-full md:w-1/2 lg:w-1/2 truncate">
                                <b style="color: #4D4F5C">Teléfono celular</b>
                            </div>
                            <div class="w-full md:w-1/1 lg:w-1/2">
                                {{ preinscription.mobile_phone }}
                            </div>
                        </div>
                    </div>
                    <div class="w-full md:w-1/2 lg:w-1/2 px-2">
                        <div class="flex flex-wrap mb-2">
                            <div class="w-full md:w-1/2 lg:w-1/2">
                                <b style="color: #4D4F5C">Lugar de nacimiento</b>
                            </div>
                            <div class="w-full md:w-1/1 lg:w-1/2">
                                {{ preinscription.birth_place }}
                            </div>
                        </div>
                        <div class="flex flex-wrap mb-2">
                            <div class="w-full md:w-1/2 lg:w-1/2 truncate">
                                <b style="color: #4D4F5C">Fecha de nacimiento</b>
                            </div>
                            <div class="w-full md:w-1/1 lg:w-1/2">
                                {{ preinscription.birth_date }}
                            </div>
                        </div>
                        <div class="flex flex-wrap mb-2">
                            <div class="w-full md:w-1/2 lg:w-1/2 truncate">
                                <b style="color: #4D4F5C">Dirección</b>
                            </div>
                            <div class="w-full md:w-1/1 lg:w-1/2">
                                {{ preinscription.address }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-full mt-8">
                <h3 class="font-bold text-gray-800 mb-4">Redes sociales</h3>
                <div class="flex flex-wrap -mx-2">
                    <div class="w-1/3 px-2">
                        <div class="flex flex-wrap items-center bg-gradient-to-br from-blue-600 to-blue-700 bg-clip-text text-transparent">
                          <a-icon name="facebook" class="mr-2 w-5 h-5 text-white" />
                            {{ preinscription.social_networks.facebook || '-- No --' }}
                        </div>
                    </div>
                    <div class="w-1/3 px-2">
                        <div class="flex flex-wrap items-center bg-gradient-to-br from-yellow-500 to-purple-600 bg-clip-text">
                          <a-icon name="instagram" class="w-6 h-6 text-white inline-block" />
                          <span class="text-transparent">{{ preinscription.social_networks.instagram || '-- No --' }}</span>
                        </div>
                    </div>
                    <div class="w-1/3 px-2">
                        <div class="flex flex-wrap items-center text-blue-400">
                          <a-icon name="twitter" class="mr-2 w-6 h-6 text-white" />
                            {{ preinscription.social_networks.twitter || '-- No --' }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-full mt-8">
                <h3 class="font-bold text-gray-800 mb-4">Estudios realizados</h3>
                <div class="w-full flex flex-wrap -mx-2">
                    <div class="w-full md:w-1/2 lg:w-1/2 px-2">
                        <div class="flex flex-wrap mb-2">
                            <div class="w-full md:w-1/2 lg:w-1/2">
                                <b style="color: #4D4F5C">Educación</b>
                            </div>
                            <div class="w-full md:w-1/1 lg:w-1/2">
                                {{ preinscription.educational_background }}
                            </div>
                        </div>
                        <div class="flex flex-wrap mb-2">
                            <div class="w-full md:w-1/2 lg:w-1/2 truncate">
                                <b style="color: #4D4F5C">Título obtenido</b>
                            </div>
                            <div class="w-full md:w-1/1 lg:w-1/2">
                                {{ preinscription.educational_title }}
                            </div>
                        </div>
                        <div class="flex flex-wrap mb-2">
                            <div class="w-full md:w-1/2 lg:w-1/2 truncate">
                                <b style="color: #4D4F5C">Actividad actual</b>
                            </div>
                            <div class="w-full md:w-1/1 lg:w-1/2">
                                {{ preinscription.educational_current_activity }}
                            </div>
                        </div>
                    </div>
                    <div class="w-full md:w-1/2 lg:w-1/2 px-2">
                        <div class="flex flex-wrap mb-2">
                            <div class="w-full md:w-1/2 lg:w-1/2">
                                <b style="color: #4D4F5C">Nombre del instituto</b>
                            </div>
                            <div class="w-full md:w-1/1 lg:w-1/2">
                                {{ preinscription.educational_institution_name }}
                            </div>
                        </div>
                        <div class="flex flex-wrap mb-2">
                            <div class="w-full md:w-1/2 lg:w-1/2 truncate">
                                <b style="color: #4D4F5C">Fecha de graduación</b>
                            </div>
                            <div class="w-full md:w-1/1 lg:w-1/2">
                                {{ preinscription.educational_graduation_date }}
                            </div>
                        </div>
                        <div class="flex flex-wrap mb-2">
                            <div class="w-full md:w-1/2 lg:w-1/2 truncate">
                                <b style="color: #4D4F5C">Especialidad</b>
                            </div>
                            <div class="w-full md:w-1/1 lg:w-1/2">
                                {{ getSpecializationName() }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-full mt-4">
                <h3 class="font-bold text-gray-800 mb-4">Datos del representante</h3>
                <div class="w-full flex flex-wrap -mx-2">
                    <div class="w-full md:w-1/2 lg:w-1/2 px-2">
                        <div class="flex flex-wrap mb-2">
                            <div class="w-full md:w-1/2 lg:w-1/2">
                                <b style="color: #4D4F5C">Nombre del representante</b>
                            </div>
                            <div class="w-full md:w-1/1 lg:w-1/2">
                                {{ preinscription.representative_name }}
                            </div>
                        </div>
                        <div class="flex flex-wrap mb-2">
                            <div class="w-full md:w-1/2 lg:w-1/2 truncate">
                                <b style="color: #4D4F5C">Email del representante</b>
                            </div>
                            <div class="w-full md:w-1/1 lg:w-1/2 truncate">
                                {{ preinscription.representative_email }}
                            </div>
                        </div>
                    </div>
                    <div class="w-full md:w-1/2 lg:w-1/2 px-2">
                        <div class="flex flex-wrap mb-2">
                            <div class="w-full md:w-1/2 lg:w-1/2">
                                <b style="color: #4D4F5C">Profesión del representante</b>
                            </div>
                            <div class="w-full md:w-1/1 lg:w-1/2">
                                {{ preinscription.representative_profession }}
                            </div>
                        </div>
                        <div class="flex flex-wrap mb-2">
                            <div class="w-full md:w-1/2 lg:w-1/2 truncate">
                                <b style="color: #4D4F5C">Dirección del representante</b>
                            </div>
                            <div class="w-full md:w-1/1 lg:w-1/2">
                                {{ preinscription.representative_address }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
             <div class="w-full mt-4">
                <h3 class="font-bold text-gray-800 mb-4">Datos del pago</h3>
                <div class="w-full flex flex-wrap -mx-2">
                    <div class="w-full md:w-1/2 lg:w-1/2 px-2">
                        <div class="flex flex-wrap mb-2">
                            <div class="w-full md:w-1/2 lg:w-1/2">
                                <b style="color: #4D4F5C">Fecha de pago</b>
                            </div>
                            <div class="w-full md:w-1/1 lg:w-1/2">
                                {{ preinscription.payment_date }}
                            </div>
                        </div>
                        <div class="flex flex-wrap mb-2">
                            <div class="w-full md:w-1/2 lg:w-1/2 truncate">
                                <b style="color: #4D4F5C">Total pagado</b>
                            </div>
                            <div class="w-full md:w-1/1 lg:w-1/2">
                                {{ '$' + parseFloat((preinscription.payment_amount / 100)).toFixed(2) }}
                            </div>
                        </div>
                    </div>
                    <div class="w-full md:w-1/2 lg:w-1/2 truncate px-2">
                        <div class="flex flex-wrap mb-2">
                            <div class="w-full md:w-1/2 lg:w-1/2 truncate">
                                <b style="color: #4D4F5C">Tipo de pago</b>
                            </div>
                            <div class="w-full md:w-1/1 lg:w-1/2">
                                {{ getPaymentType() }}
                            </div>
                        </div>
                        <div class="flex flex-wrap mb-2">
                            <div class="w-full md:w-1/2 lg:w-1/2 truncate">
                                <b style="color: #4D4F5C">Número de recibo</b>
                            </div>
                            <div class="w-full md:w-1/1 lg:w-1/2">
                                {{ preinscription.payment_receipt_number }}
                            </div>
                        </div>
                    </div>
                </div>
             </div>
        </a-card>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
    computed: mapState({
        preinscription: state => state.preinscriptions.current
    }),
    data () {
        return {
            specName: ''
        }
    },
    methods: {
        ...mapActions({
            fetchPreincription: 'preinscriptions/fetch'
        }),
        getSheduleShift(shift) {
           if (shift === 'morning') return 'Mañana';
           if (shift === 'afternoon') return 'Tarde';
           return 'Mixto'
        },
        getSpecializationName () {
            this.$repository.specializations
                .index()
                .then((response) => {
                    const specValues = response.data.data
                    for (let i=0; i < specValues.length; i++) {
                        if (specValues[i].id === this.preinscription.specialization_id) {
                            this.specName = specValues[i].title
                        }
                    }
                })
            return this.specName
            // this.$repository.specializations
            //     .show(id)
            //     .then((response) => (console.log(response)))
        },
        getPaymentType() {
            switch (this.preinscription.payment_type) {
                case 1: {
                  return 'Efectivo'
                }
                case 2: {
                  return '100% Banco'
                }
                case 3: {
                  return 'Transferencia Bancaria'
                }
                case 4: {
                  return 'Transferencia de Curso'
                }
                case 5: {
                  return 'Zelle'
                }
            }
        }
    },
    created() {
        this.fetchPreincription({
            id: this.$route.params.preinscription_id,
            query: {
                with: `socialNetworks`
            }
        })
    }
}
</script>
